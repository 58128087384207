import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuesax from "vuesax"; //追加したよ
import "vuesax/dist/vuesax.css"; //追加したよ
import "material-icons/iconfont/material-icons.css"; //追加したよ

Vue.use(Vuesax); //追加したよ

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
