<template>
  <div id="app">
    <vs-navbar type="gradient" v-model.number="activeItem" class="nabarx">
      <div slot="title">
        <vs-navbar-title>
          OpenAI API
        </vs-navbar-title>
      </div>

      <vs-navbar-item index="0">
        <router-link to="/">whisper-1</router-link>
      </vs-navbar-item>
      <!-- <vs-navbar-item index="1">
        <router-link to="/create-image">画像生成</router-link>
      </vs-navbar-item>
      <vs-navbar-item index="2">
        <router-link to="/liff">Liffテスト</router-link>
      </vs-navbar-item> -->
    </vs-navbar>
    <router-view class="content" />
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    //HelloWorld
  },
  data() {
    return {
      activeItem: 0,
    }
  },
}
</script>

<style lang="scss">
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;

  .vs-card--footer {
    width: 100%;
  }

  .content {
    flex-grow: 1 !important;


    >.vs-row {
      height: 100%;
    }

    >* {
      height: 100%;
    }

    .con-vs-card {
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;

      .vs-card--content {
        justify-content: end;
        flex-grow: 1;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;

        >div {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }
  }


}

.vs-navbar {
  padding: 10px
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.is-active-item .router-link-active {
  color: aliceblue !important;
}
</style>
