<template>
  <div>
    <vs-row style="padding: 20px 30px">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-card style="padding: 20px">
          <div slot="header">
            <h3>whisper-1</h3>
          </div>
          <div>
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <input type="file" @change="onFileChange" v-if="view" />
              </vs-col>
              <vs-col vs-type="" style="margin-top: 10px" vs-w="12">
                <vs-select placeholder="response_format" v-model="restype">
                  <vs-select-item text="json" value="json">
                    json
                  </vs-select-item>
                  <vs-select-item text="text" value="text">
                    text
                  </vs-select-item>
                  <vs-select-item text="srt" value="srt"> srt </vs-select-item>
                  <vs-select-item text="verbose_json" value="verbose_json">
                    verbose_json
                  </vs-select-item>
                  <vs-select-item text="vtt" value="vtt"> vtt </vs-select-item>
                </vs-select>
              </vs-col>
              <vs-col
                vs-type="flex"
                style="margin-top: 10px"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <vs-textarea
                  label="プロンプト"
                  counter="2000"
                  v-model="input"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-
                vs-w="12"
              >
                <vs-button
                  @click="input = null"
                  :disabled="!input"
                  style="padding: 5px 50px; margin-right: 10px"
                  gradient
                >
                  プロンプトをクリア
                </vs-button>
                <vs-button
                  @click="send"
                  :disabled="!file || disabled"
                  style="padding: 5px 50px"
                  gradient
                >
                  送信
                </vs-button>
              </vs-col>
            </vs-row>
            <vs-list>
              <vs-list-item
                style="white-space: pre-wrap"
                :key="index"
                :title="item.title"
                :subtitle="item.value"
                v-for="(item, index) in histories"
              />
            </vs-list>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "axios";
//import Vuesax from "vuesax";
export default {
  data() {
    return {
      file: null,
      transcription: "",
      name: null,
      restype: "json",
      histories: [],
      input: null,
      view: true,
      disabled: false,
    };
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
      this.name = event.target.files[0].name;
    },
    send() {
      let formData = new FormData();
      this.disabled = true;
      formData.append("model", "whisper-1");
      formData.append("file", this.file);
      if (this.input) {
        formData.append("prompt", this.input);
      }
      if (this.restype) {
        formData.append("response_format", this.restype);
      }
      formData.append("language", "ja");

      // Specify custom headers
      const headers = {
        "Content-Type": "multipart/form-data", // Necessary for file upload
        Authorization:
          "Bearer sk-proj-qr0dLJm4rHpr39wJKjSpT3BlbkFJKAZ35O7l4mkxKWYpNOEh", // Example of authorization header
      };
      let vm = this;
      axios
        .post("https://api.openai.com/v1/audio/transcriptions", formData, {
          headers,
        })
        .then((response) => {
          console.log(response);
          vm.histories.push({
            title: vm.name,
            value: response.data.text ? response.data.text : response.data,
          });
          // Handle response if needed
          vm.file = null;
          vm.name = null;
          vm.disabled = false;
          vm.view = false;
          vm.$nextTick(function () {
            vm.view = true;
          });
        })
        .catch((error) => {
          vm.disabled = false;
          console.error("Error uploading file:", error);
          // Handle error if needed
        });
    },
    openNotification(title, content) {
      this.$vs.notification({
        position: "top-center",
        title: title,
        text: content,
      });
    },
  },
};
</script>
